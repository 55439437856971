<adxad-loader
  *ngIf="isLoading"
  [diameter]="40"
  adxadModalLoader
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>{{ isNewDsp ? 'Add new DSP' : 'Edit ' + this.config.data.name }}</h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <adxad-tabs>
      <!--  General   -->
      <adxad-tab>
        <adxad-tab-label>
          General
          <adxad-tab-label-status-icon [status]="getTabStatus()" />
        </adxad-tab-label>

        <adxad-tab-body>
          <div class="row">
            <div class="col-7">
              <div class="row">
                <adxad-form-field class="field-group col-6">
                  <adxad-input
                    [autofocus]="true"
                    formControlName="name"
                    label="Name"
                    placeholder="Enter DSP name"
                  />
                  <app-show-errors [control]="form.get('name')" />
                </adxad-form-field>

                <adxad-form-field class="field-group col-6">
                  <adxad-input
                    formControlName="extId"
                    label="External ID"
                    placeholder="Enter DSP external id"
                  />
                  <app-show-errors [control]="form.get('extId')" />
                </adxad-form-field>
              </div>

              <div class="row">
                <adxad-form-field class="field-group col-4">
                  <adxad-number-input
                    formControlName="rpsLimit"
                    label="RPS limit"
                    step="1"
                  />
                  <app-show-errors [control]="form.get('rpsLimit')" />
                </adxad-form-field>

                <adxad-form-field class="field-group col-4">
                  <adxad-select
                    [options]="dicts.costModel.data"
                    formControlName="costModel"
                    label="Cost model"
                    placeholder="Select cost model"
                  />
                  <app-show-errors [control]="form.get('costModel')" />
                </adxad-form-field>

                <adxad-form-field class="field-group col-4">
                  <adxad-number-input
                    formControlName="maxClicksPerIp"
                    label="Freq cap"
                    step="1"
                  />
                  <app-show-errors [control]="form.get('maxClicksPerIp')" />
                </adxad-form-field>

                <adxad-form-field class="field-group col-4">
                  <adxad-number-input
                    formControlName="commission"
                    label="Default revshare (%)"
                    step="1"
                  />

                  <app-show-errors [control]="form.get('commission')" />
                </adxad-form-field>

                <adxad-form-field class="field-group col-4">
                  <adxad-select
                    [options]="dicts.advType.data"
                    formControlName="advType"
                    label="Advert type"
                    placeholder="Select advert type"
                  />
                  <app-show-errors [control]="form.get('advType')" />
                </adxad-form-field>

                <adxad-form-field class="field-group col-4">
                  <adxad-input
                    *ngIf="!form.get('isOpenRtb').value"
                    formControlName="responseType"
                    label="Response type"
                  />

                  <adxad-select
                    *ngIf="form.get('isOpenRtb').value"
                    [options]="dicts.responseType.data"
                    formControlName="responseType"
                    label="Response type"
                    placeholder="Select response type"
                  />
                  <app-show-errors [control]="form.get('responseType')" />
                </adxad-form-field>

                <div class="col-12 --checkbox-group">
                  <adxad-form-field class="field-group">
                    <adxad-checkbox
                      (changeChecked)="isOpenRtbChange($event)"
                      formControlName="isOpenRtb"
                    >
                      Open RTB
                    </adxad-checkbox>
                  </adxad-form-field>

                  <adxad-form-field class="field-group">
                    <adxad-checkbox formControlName="isWinNotification"> Win notification </adxad-checkbox>
                  </adxad-form-field>

                  <adxad-form-field class="field-group">
                    <adxad-checkbox formControlName="testMode"> Test mode </adxad-checkbox>
                  </adxad-form-field>

                  <adxad-form-field class="field-group">
                    <adxad-checkbox formControlName="isInternal"
                                    (changeChecked)="isInternalChange($event)"
                    >
                      Is adverts demand
                    </adxad-checkbox>
                  </adxad-form-field>
                </div>

                @if (form.get('isOpenRtb').value) {
                  <adxad-form-field class="field-group col-12">
                    <adxad-select
                      [options]="dicts.typeOfZone.data"
                      formControlName="typeOfZone"
                      label="Type of zone"
                      placeholder="Select type of zone"
                    />
                    <app-show-errors [control]="form.get('typeOfZone')" />
                  </adxad-form-field>
                }

                <adxad-form-field class="field-group col-4">
                  <adxad-select
                    [options]="dicts.redirectType.data"
                    formControlName="redirectType"
                    label="Redirect type"
                    placeholder="Select Redirect type"
                  />
                  <app-show-errors [control]="form.get('redirectType')" />
                </adxad-form-field>

                <adxad-form-field class="field-group col-8">
                  <adxad-chips-input
                    formControlName="domains"
                    label="Redirect domains"
                    placeholder="Enter domains name"
                    [isValid]="domainsControl.valid"
                  >
                    <app-show-errors [control]="domainsControl" />
                  </adxad-chips-input>
                </adxad-form-field>
              </div>
            </div>

            <div class="col-5">
              <adxad-form-field class="field-group">
                <adxad-textarea
                  (blurEvent)="setCursorPosition($event)"
                  [fixWidth]="true"
                  [height]="'160'"
                  formControlName="url"
                  label="URL"
                  placeholder="Enter DSP url"
                ></adxad-textarea>
                <app-show-errors [control]="form.get('url')" />
              </adxad-form-field>

              <adxad-holders
                (changeHolder)="toggleUrlPlaceholders($event)"
                [holders]="dicts.urlPlaceholders.data"
                [text]="form.get('url').value"
                label="URL placeholders"
              ></adxad-holders>
            </div>
          </div>
        </adxad-tab-body>
      </adxad-tab>
      <!--    End general   -->

      <!-- Custom parser params -->
      <adxad-tab
        *ngIf="!form.get('isOpenRtb').value"
        formGroupName="decoder"
      >
        <adxad-tab-label> Custom parser </adxad-tab-label>
        <adxad-tab-body>
          <div
            class="row"
            formGroupName="path"
          >
            <div class="col-6">
              <adxad-form-field class="field-group">
                <adxad-input
                  formControlName="bid"
                  label="Bid path"
                  placeholder="Enter bid path"
                />
              </adxad-form-field>
            </div>
            <div class="col-6">
              <adxad-form-field class="field-group">
                <adxad-input
                  formControlName="clickUrl"
                  label="Click url path"
                  placeholder="Enter click url path"
                />
              </adxad-form-field>
            </div>
            <div class="col-12">
              <adxad-form-field class="field-group">
                <adxad-input
                  formControlName="nurl"
                  label="Nurl path"
                  placeholder="Enter nurl path"
                />
              </adxad-form-field>
            </div>
            <div
              *ngIf="(form.get('advType').value === 'push')"
              class="col-6"
            >
              <adxad-form-field class="field-group">
                <adxad-input
                  formControlName="pushTitle"
                  label="Title path"
                  placeholder="Enter title path"
                />
              </adxad-form-field>
            </div>
            <div
              *ngIf="(form.get('advType').value === 'push')"
              class="col-6"
            >
              <adxad-form-field class="field-group">
                <adxad-input
                  formControlName="pushDescription"
                  label="Description path"
                  placeholder="Enter description path"
                />
              </adxad-form-field>
            </div>
            <div
              *ngIf="(form.get('advType').value === 'push')"
              class="col-6"
            >
              <adxad-form-field class="field-group">
                <adxad-input
                  formControlName="pushIconUrl"
                  label="Icon url path"
                  placeholder="Enter icon url path"
                />
              </adxad-form-field>
            </div>

            <div
              *ngIf="(form.get('advType').value === 'push')"
              class="col-6"
            >
              <adxad-form-field class="field-group">
                <adxad-input
                  formControlName="pushImageUrl"
                  label="Image url path"
                  placeholder="Enter image url path"
                />
              </adxad-form-field>
            </div>
          </div>
        </adxad-tab-body>
      </adxad-tab>
      <!-- End custom parser params -->

      <!--    Filters   -->
      <adxad-tab>
        <adxad-tab-label> Filters </adxad-tab-label>

        <adxad-tab-body>
          <adxad-exchange-filter [group]="$any(this.form.get('filters'))" />
        </adxad-tab-body>
      </adxad-tab>
      <!--    End filters   -->

      <!--    Geo revshare   -->
      <adxad-tab>
        <adxad-tab-label> Geo revshare </adxad-tab-label>

        <adxad-tab-body>
          <adxad-geo-revshare
            [maxValue]="200"
            [revShares]="revShares"
          />
        </adxad-tab-body>
      </adxad-tab>
      <!--    End geo revshare   -->
    </adxad-tabs>
  </adxad-modal-content>

  <adxad-modal-actions class="row">
    <div class="col">
      <button
        (click)="close()"
        adxadStrokedButton
        class="w100"
      >
        Cancel
      </button>
    </div>

    <div class="col">
      <adxad-submit-button
        (click)="submit()"
        [disabled]="!form.valid || isLoadingSubmit"
        [isLoading]="isLoadingSubmit"
      >
        {{ (isNewDsp ? 'Create' : 'Save') | uppercase }}
      </adxad-submit-button>
    </div>
  </adxad-modal-actions>
</ng-container>
